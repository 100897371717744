import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './App.css'
import { UserProvider } from './context/userContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <App />
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// import React, { Suspense } from 'react';
// import ReactDOM from 'react-dom/client';
// import { BrowserRouter } from 'react-router-dom';
// import { UserProvider } from './context/userContext';
// import Loader from './Loader';
// const App = React.lazy(() => import('./App'));


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <UserProvider>
//         <Suspense fallback={<div style={{ height: '100vh' }}><Loader /></div>}>
//           <App />
//         </Suspense>
//       </UserProvider>
//     </BrowserRouter>
//   </React.StrictMode>
// );

