// import React from 'react'
// import { Rings } from "react-loader-spinner";
// const Loader = () => {
//     return (
//         <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
//             <Rings
//                 visible={true}
//                 height="80"
//                 width="80"
//                 color="green"
//                 ariaLabel="rings-loading"
//             />
//         </div>
//     )
// }

// export default Loader
import React, { useEffect, useState } from 'react';
import { Rings } from "react-loader-spinner";

const Loader = () => {
    const [loaderColor, setLoaderColor] = useState("green");

    useEffect(() => {

        const themeColor = getComputedStyle(document.documentElement).getPropertyValue("--theme-color").trim();


        setLoaderColor(themeColor || "green");
    }, []);

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Rings
                visible={true}
                height="80"
                width="80"
                color={loaderColor}
                ariaLabel="rings-loading"
            />
        </div>
    );
};

export default Loader;
